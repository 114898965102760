.browse-header-container {
    height: auto;
    overflow: hidden;
    position: relative;
}

.browse-header-image-wrapper {
    display: inline-block;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
}

.browse-header-image {
    height: auto;
    width: 100%;
    z-index: 0;
}