.summary-large-container {
    height: auto;
    overflow: hidden;
    position: relative;
}

.summary-large-poster-container {
    display: inline-block;
    left: 40%;
    position: relative;
    right: 0;
    top: 0;
    width: 60%;
}

.summary-large-gradient-overlay {
    background: linear-gradient(to right, black, transparent);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 20%;
    z-index: 10;
}

.summary-large-poster-image {
    height: auto;
    width: 100%;
    z-index: 0;
}

.summary-large-details-overlay {
    height: 100vh;
    left: 40px;
    position: absolute;
    top: 30px;
    width: 40vw;
    z-index: 10;
}

.summary-large-movie-title {
    color: white;
    font-size: 3vw;
    font-weight: bold;
}

.summary-large-movie-year,
.summary-large-movie-rating,
.summary-large-movie-duration {
    display: inline-block;
    font-size: 1.0vw;
    margin-right: 10px;
    vertical-align: middle;
}

.summary-large-movie-year,
.summary-large-movie-duration {
    color: #777;
    font-weight: normal;
    margin-top: 8px;
}

.summary-large-movie-rating {
    background-color: #282828;
    border-radius: 5px;
    color: #999;
    font-weight: normal;
    margin-top: 8px;
    padding: 5px 10px;
}

.summary-large-movie-synopsis {
    color: #bbb;
    font-size: 1.3vw;
    font-weight: normal;
    margin-top: 20px;
    width: 35vw;
}

.summary-large-button-container {
    align-items: center;
    display: flex;
}

.summary-large-button-watch,
.summary-large-button-edit {
    font-size: 1.4vw;
}

.summary-large-button-watch {
    align-items: center;
    background-color: #eee;
    border: none;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    display: flex;
    margin-right: 8px;
    margin-top: 30px;
    padding: 10px 20px;
}

.summary-large-button-watch-icon {
    height: 14px;
    margin-right: 8px;
    margin-bottom: 1px;
    opacity: 0.2;
    width: 14px;
}

.summary-large-button-watch-disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.summary-large-button-edit {
    background-color: #444;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 30px;
    opacity: 0.5;
    padding: 10px 20px;
}