.genre-list {
  margin-left: 14px;
}

.genre-wrapper {
  margin-bottom: 12px;
}

.genre-name {
  font-weight: bold;
  margin-bottom: 14px;
}

.genre-movies-wrapper {
  display: flex;
  overflow-x: auto;
  /* enables horizontal scrolling */
  overflow-y: hidden;
  /* prevents vertical scrolling */
  white-space: nowrap;
  /* ensures the items inside do not wrap */

  /* Hide scrollbar for Firefox */
  scrollbar-width: none !important;

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none !important;
}

.genre-movies-wrapper::-webkit-scrollbar {
  display: none;
}

/* This will style each individual movie item */
.browse-movie-item {
  position: relative;
  /* This will make sure the absolute positioning of .indicator is with respect to this container */
  margin-right: 0px;
  width: 170px;
}

/* This will style each individual movie item */
.movie-item {
  position: relative;
  /* This will make sure the absolute positioning of .indicator is with respect to this container */
  margin-right: 8px;
  width: 170px;
}

.movie-image {
  border: 1px solid #333;
  border-radius: 7px;
  width: 170px;
  /* Makes sure image takes full width of its container */
  height: auto;
  z-index: 0;
  /* Maintains image's aspect ratio */
}

/* CSS for the indicator container */
.indicator {
  position: absolute;
  /* Position it relative to its parent (the image container) */
  top: 8px;
  /* Align it to the top of the parent */
  right: 8px;
  /* Align it to the left of the parent */
  width: 8px;
  /* Adjust the width as needed */
  height: 8px;
  /* Adjust the height as needed */
  background-color: #00ff00;
  /* Bright green background color */
  border-radius: 50%;
  /* Create a circular shape */
  display: flex;
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  /* Ensure it's above the image */
}

/* CSS for the indicator circle (centered within the container) */
.indicator-circle {
  padding: 0px;
  margin: 0px;
  width: 8px;
  /* Adjust the circle size as needed */
  height: 8px;
  /* Adjust the circle size as needed */
  background-color: #00ff00;
  /* White circle color */
  border-radius: 50%;
  /* Create a circular shape */
}