@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

body {
  font-family: 'Trebuchet MS', 'Open Sans', sans-serif;
}




.images-container {
  position: relative; /* Enables absolute positioning for child elements */
}

.images-poster-container {
  position: relative; /* Use relative here so children can be positioned with respect to this container */
  z-index: 1; /* This ensures the poster is below the thumbnail */
  width: 100%; /* Let the container take up full width */
}

.poster-container {
  position: relative; /* Used as a base for absolute positioning of children */
}

.poster-image {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  transform: translate(-50%, -50%);
  minWidth: '100%';
}

.images-thumbnail-container {
  background-color: black;
  border-radius: 17px;
  position: absolute; /* This will allow us to overlap the thumbnail over the poster */
  bottom: -50px; /* Overlaps the thumbnail 50px on top of the poster */
  right: -10px; /* Overlaps the thumbnail 50px on top of the poster */
  width: auto; /* Let the container take up full width */
  z-index: 10; /* Ensures the thumbnail is above the poster */
  overflow: visible;
}

/* Container for the movie poster */
.thumbnail-container {
  border: 10px solid black;
  border-radius: 17px;
  width: 240px; /* Makes sure image takes full width of its container */
  height: 135px; /* Maintains image's aspect ratio */
  overflow: hidden;
  position: relative;
  margin-bottom: 0px;
}

.thumbnail-image {
  position: absolute;
  border-radius: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  minWidth: '100%';
  width: 240px; /* Makes sure image takes full width of its container */
  height: 135px; /* Maintains image's aspect ratio */
  transform: 'translate(-50%, -50%)';
}


/* Container for the movie poster */
.poster-container {
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  width: 600px;
  height: 337px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0px;
  margin-top: 12px;
}

/* Styling the movie poster */
.poster-image {
  position: 'absolute';
  left: '50%';
  top: '50%';
  height: 90px;
  width: 160px;
  transform: 'translate(-50%, -50%)';
  minWidth: '160px'
}

.poster-info {
  color: #999;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 3px;
}

.thumbnail-info {
  color: #999;
  font-size: 12px;
  right: 3px;
  width: 90%;
  text-align: right;
}

/* CSS for the indicator container */
.indicator {
  position: absolute;
  /* Position it relative to its parent (the image container) */
  top: 8px;
  /* Align it to the top of the parent */
  right: 8px;
  /* Align it to the left of the parent */
  width: 8px;
  /* Adjust the width as needed */
  height: 8px;
  /* Adjust the height as needed */
  background-color: #00ff00;
  /* Bright green background color */
  border-radius: 50%;
  /* Create a circular shape */
  display: flex;
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  /* Ensure it's above the image */
}

/* CSS for the indicator circle (centered within the container) */
.indicator-circle {
  padding: 0px;
  margin: 0px;
  width: 8px;
  /* Adjust the circle size as needed */
  height: 8px;
  /* Adjust the circle size as needed */
  background-color: #00ff00;
  /* White circle color */
  border-radius: 50%;
  /* Create a circular shape */
}

/* CSS for the indicator container */
.no-thumb-indicator {
  position: absolute;
  /* Position it relative to its parent (the image container) */
  top: 8px;
  /* Align it to the top of the parent */
  right: 8px;
  /* Align it to the left of the parent */
  width: 8px;
  /* Adjust the width as needed */
  height: 8px;
  /* Adjust the height as needed */
  background-color: #00ff00;
  /* Bright green background color */
  border-radius: 50%;
  /* Create a circular shape */
  display: flex;
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  /* Ensure it's above the image */
}

/* CSS for the indicator circle (centered within the container) */
.no-thumb-indicator-circle {
  padding: 0px;
  margin: 0px;
  width: 8px;
  /* Adjust the circle size as needed */
  height: 8px;
  /* Adjust the circle size as needed */
  background-color: red;
  /* White circle color */
  border-radius: 50%;
  /* Create a circular shape */
}

/* CSS for the indicator container */
.no-lesson-indicator {
  position: absolute;
  /* Position it relative to its parent (the image container) */
  bottom: 12px;
  /* Align it to the top of the parent */
  right: 8px;
  /* Align it to the left of the parent */
  width: 8px;
  /* Adjust the width as needed */
  height: 8px;
  /* Adjust the height as needed */
  background-color: #ffff00;
  /* Bright green background color */
  border-radius: 50%;
  /* Create a circular shape */
  display: flex;
  padding: 0px;
  margin: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  /* Ensure it's above the image */
}

/* CSS for the indicator circle (centered within the container) */
.no-lesson-indicator-circle {
  padding: 0px;
  margin: 0px;
  width: 8px;
  /* Adjust the circle size as needed */
  height: 8px;
  /* Adjust the circle size as needed */
  background-color: #ffff00;
  /* White circle color */
  border-radius: 50%;
  /* Create a circular shape */
}

.movie-image {
}

/* This will style each individual movie item */
.movie-item {
  margin-right: 4px;
  width: 150px;
}

.movie-image {
  border-radius: 5px;
  height: auto; /* Maintains image's aspect ratio */
  margin-right: 10px; /* Add some space between the movie thumbnails */
  width: 150px; /* Makes sure image takes full width of its container */
}

/* Container for the movie list screen */
.movie-list-container {
  width: 94%;
  margin: auto;
  padding: 20px;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

/* This will make the list container act as a flex container */
.movie-list {
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  gap: 4px; /* Provides space between items */
  list-style-type: none; /* Removes default bullet points */
  padding: 0; /* Removes default padding */
}

.search-field {
  background-color: #333;
  border: 1px solid #555;
  border-radius: 5px;
  color: white;
  padding: 15px;
  font-size: 18px;
  font-weight: normal;
  margin-top: 40px;
  width: 400px;
  outline: 0;
}

/* CSS for the indicator container */
.indicator {
  position: absolute; /* Position it relative to its parent (the image container) */
  top: 0; /* Align it to the top of the parent */
  right: 0; /* Align it to the left of the parent */
  width: 24px; /* Adjust the width as needed */
  height: 24px; /* Adjust the height as needed */
  background-color: #00ff00; /* Bright green background color */
  border-radius: 50%; /* Create a circular shape */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Ensure it's above the image */
}

/* CSS for the indicator circle (centered within the container) */
.indicator-circle {
  width: 12px; /* Adjust the circle size as needed */
  height: 12px; /* Adjust the circle size as needed */
  background-color: white; /* White circle color */
  border-radius: 50%; /* Create a circular shape */
}



/* Hiding the default file input */
.form-input[type="file"] {
  opacity: 0;
  position: absolute;
  z-index: -1; /* Makes sure it's beneath other elements */
  width: 1px;
  height: 1px;
}

/* Styling the label to look like a button */
.fileInputLabel {
  margin-top: 10px;
  display: inline-block;
  padding: 8px 16px;
  background-color: #e50a15; /* The desired color for the button */
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s;
}

.fileInputLabel:hover {
  background-color: darken(#e50a15, 10%); /* Slightly darken on hover for feedback */
}





a {
  color: #e50a15;
  text-decoration: none;
}

a:visited {
  color: #e50a15;         
}

li {
  margin-top: 4px;
}

body, .form-container {
  background-color: #000000; /* Dark background color */
  color: #FFFFFF; /* Light text color */
}

.breadcrumbs {
  font-size: 12px;
}

.browse-button {
  margin-left: 10px;
}

/* Container for the entire form */
.form-container {
  width: 94%;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

/* Styling the form title */
.form-title {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Adjusted input elements for dark mode */
.form-label .form-input,
.form-label .form-textarea
 {
  margin-top: 14px;
}

.form-label,
.form-input,
.form-textarea {
  width: 100%;
  margin-bottom: 16px;
  color: #EAEAEA; /* Light grey text for labels and inputs */
}

.form-input,
.form-textarea {
  background-color: #303030; /* Darker background for input elements */
  border: 1px solid #444444; /* Border for input elements */
  border-radius: 5px;
  padding: 12px;
  width: calc(100% - 32px);
  margin-top: 6px;
  color: #EAEAEA; /* Light grey text for inputs */
}

.form-textarea {
  background-color: #303030; /* Darker background for textareas */
  border: 1px solid #444444; /* Border for textarea */
  height: 70px;
  font-family: 'Arial', sans-serif;
}

/* Styling the update button */
.form-button {
  background-color: #e50a15;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #555;
}

.success-message {
  color: white;
  margin-left: 20px;
  font-weight: bold;
}














