.summary-fade {
    transition: opacity 1s ease;
    /* This enables the fading effect */
    opacity: 1;
    /* Initial opacity set to fully visible */
    position: relative;
    width: 100%;
    /* Full width of its parent */
    padding-top: 56.25%;
    /* Maintain the aspect ratio */
    margin-bottom: 16px;
    /* Maintain the rest of your styles for this container */
}

/* Media query to hide info on mobile */
@media (min-width: 767px) {

    .summary-fade {
        padding-top: 34.25%
    }

}


.browse-header-large {
    display: block;
}

.browse-header-small {
    display: none;
}

@media (max-width: 767px) {

    .browse-header-large {
        display: none;
    }
    
    .browse-header-small {
        display: block;
    }

}








/* This is the styling for the header wrapper */
.header-wrapper {
    height: auto;
    width: 100%;
    display: flex;
    /* Enables flexbox layout on the container */
    justify-content: space-between;
    /* Distributes space between items */
    align-items: center;
    /* Aligns items vertically in the center */
}

/* Styling for the settings image to appear on the left */
.settings-image {
    /* If you need specific styling for the settings image, add it here */
    max-height: 20px;
    /* Example to ensure images are not too large */
    max-width: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 24px;
    margin-right: auto;
    /* Pushes the image to the left */
}

/* Styling for the logo image to be centered */
.logo-image {
    margin: 12px;
    /* Central image might need to be larger than others */
    max-height: 30px;
    /* Example to ensure the logo is visible and not too large */
    /* No margin needed here, flexbox takes care of centering */
}

/* Styling for the search image to appear on the right */
.search-image {
    /* If you need specific styling for the search image, add it here */
    max-height: 20px;
    /* Example to ensure images are not too large */
    max-width: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: auto;
    /* Pushes the image to the right */
    margin-right: 24px;
}