.summary-small-container {
    height: auto;
    overflow: hidden;
    position: relative;
}

.summary-small-poster-container {
    display: inline-block;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
}

.summary-small-poster-image {
    height: auto;
    width: 100%;
    z-index: 0;
}

.summary-small-details-container {
    position: relative;
    width: 100%;
    z-index: 10;
}

.summary-small-movie-title {
    color: white;
    font-size: 26px;
    font-weight: bold;
    margin-left: 25px;
    margin-top: 20px;
}

.summary-small-movie-props {
    color: #999;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-left: 25px;
    margin-top: 14px;
}

.summary-small-buttons-wrapper {
    display: flex;          
    flex-direction: row;
}

.summary-small-button-wrapper {
    display: flex;       
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    margin-right: 15px;
    margin-top: 40px;
}

.summary-small-button {
    width: 40px;
}

.summary-small-button-text {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
}

.summary-small-director {
    color: #999;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-left: 25px;
    margin-top: 40px;
}

.summary-small-movie-synopsis {
    color: white;
    font-size: 16px;
    font-weight: normal;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 26px;
}

.summary-small-actors {
    color: #999;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 30px;
    width: 100%;
}

.summary-small-genres {
    color: #999;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 30px;
    width: 100%;
}














.summary-small-button-container {
    align-items: center;
    display: flex;
}

.summary-small-button-watch,
.summary-small-button-edit {
    font-size: 1.4vw;
}

.summary-small-button-watch {
    align-items: center;
    background-color: #eee;
    border: none;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    display: flex;
    margin-right: 8px;
    margin-top: 30px;
    padding: 10px 20px;
}

.summary-small-button-watch-icon {
    height: 14px;
    margin-right: 8px;
    margin-bottom: 1px;
    opacity: 0.2;
    width: 14px;
}

.summary-small-button-watch-disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.summary-small-button-edit {
    background-color: #444;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    margin-top: 30px;
    opacity: 0.5;
    padding: 10px 20px;
}