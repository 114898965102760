.intro-large {
    display: block;
}

.intro-small {
    display: none;
}

@media (max-width: 767px) {

    .intro-large {
        display: none;
    }
    
    .intro-small {
        display: block;
    }

}